window.toastr = require("toastr");

// TODO: Probably put these in an athlean-x config file.
toastr.options = {
    debug: false,
    progressBar: true,
    closeButton: false,
    timeOut: 5000,
    extendedTimeOut: 3000,
    // positionClass: "toast-bottom-center",
    positionClass: "toast-bottom-right",
    showEasing: 'linear',
    hideEasing: 'linear',
    closeEasing: 'linear',
    showMethod: 'slideDown',
    hideMethod: 'fadeOut',
    closeMethod: 'fadeOut',
    showDuration: 150,
    hideDuration: 300,
    closeDuration: 300,
    onShown: null,
    onHidden: null,
    onClick: null,
    onCloseClick: null,
};

// Show toastr alert via js
AX.alert = function (type, message, duration, callbacks, classes) {
    if (callbacks && callbacks.onShown) (toastr.options.onShown = callbacks.onShown);
    if (callbacks && callbacks.onHidden) (toastr.options.onHidden = callbacks.onHidden);
    if (callbacks && callbacks.onClick) (toastr.options.onClick = callbacks.onClick);
    if (callbacks && callbacks.onCloseClick) (toastr.options.onCloseClick = callbacks.onCloseClick);

    if (typeof duration !== 'undefined' && duration != null) {
        toastr.options.timeOut = duration;
    }

    // If extra classes are provided for the alert, stick them in the onShown callback.
    if (typeof classes !== 'undefined' && classes.length) {
        toastr.options.onShown = function (event) {
            $('#toast-container').addClass(classes);
            if (callbacks && callbacks.onShown) {
                callbacks.onShown();
            }
        };
    }

    toastr[type](message);
};
