AX.forms = AX.forms || {};

// Add any misc form related event handlers here.
AX.forms.attachEventHandlers = function () {
    // Checkbox toggleswitch handler
    $('.pretty.p-switch.p-ajax input[type="checkbox"]')
        .off('change')
        .on('change', function (event) {
            AX.forms.toggleSwitchHandler(event);
        });

    // Ajax form submit handler.
    $(document)
        .off('submit.ax.ajax-form', 'form[data-ajax-form]')
        .on('submit.ax.ajax-form', 'form[data-ajax-form]', function (event) {
            AX.forms.handleAjaxSubmit(event);
        });

    // Handle toggle switch changes.
    $(document)
        .off('ax.toggle.change')
        .on('ax.toggle.change', function (event, data) {
            AX.forms.handleAjaxToggle(event, data);
        });

    AX.forms.attachSpinClickHandler();
};

// Build a hidden inline form using js and submit it.
AX.forms.inlineForm = function (options) {
    var $form = $('<form>');
    $form
        .attr('action', options.action)
        .attr('method', options.method)
        .attr('target', options.target || 'iframe');

    var inputs = '';
    $.each(options.data, function (field, value) {
        inputs += '<input name="' + field + '" value="' + value + '" type="hidden" />';
    });
    $form.appendTo('body').append(inputs).submit().delay(500).remove();

    if (typeof options.alert !== 'undefined') {
        AX.alert('success', options.alert);
    }

    if (typeof options.callback !== 'undefined') {
        options.callback(options.el);
    }
};

// Catch a form submit and submit via ajax.
AX.forms.handleAjaxSubmit = function (event) {
    event.preventDefault();
    event.stopPropagation();

    var $form = $(event.target)
        , action = $form.attr('action')
        , method = $form.attr('method')
        , $button = $form.find('button[type="submit"]')
        , btnHtml = $button.html()
        , preloaderHtml = '<i class="fa fa-spin fa-spinner"></i>'
        , formData = $form.serializeArray();

    // Append ajax submission flag to request.
    formData.push({ name: 'isAjax', value: true });

    $form.trigger('ax.form.submitted', {
        $form: $form,
    });

    // Disable button and show preloader.
    $button.css('width', $button.css('width')).html(preloaderHtml).prop('disabled', true);

    $.ajax({
        url: action,
        method: method,
        data: formData,
    })
        .done(function (response) {
            var reload = true
                , redirect = false;

            // Check for reload/redirect params.
            if (typeof response.data !== 'undefined' && response.data != null) {
                if (typeof response.data.reload !== 'undefined') {
                    reload = response.data.reload;
                }
                if (typeof response.data.redirect !== 'undefined') {
                    redirect = response.data.redirect;
                }
            }

            if (response.status == 200) {
                AX.alert('success', response.message);
                if (redirect) {
                    window.location.href = redirect;
                } else {
                    $form.trigger('ax.form.success', {
                        $form: $form,
                        response: response,
                        reload: reload
                    });
                }
            } else {
                AX.log(response);
                AX.alert('error', response.message || 'Ajax request error.');
                $form.trigger('ax.form.error', {
                    $form: $form,
                    response: response,
                    reload: reload
                });
            }

            $button.html(btnHtml).prop('disabled', false);
        })
        .fail(function (jqXHR, textStatus, err) {
            AX.log(err);
            AX.alert('error', err);
            $form.trigger('ax.form.fail', {
                $form: $form,
                response: err,
                reload: false
            });
            $button.html(btnHtml).prop('disabled', false);
        })
        .always(function () {
            $button.html(btnHtml).prop('disabled', false);
        });

    return false;
};

// Event handler for "pretty checkbox" package toggle switch checkboxes.
AX.forms.toggleSwitchHandler = function (event) {
    var $this = $(event.target).closest('input[type="checkbox"]');

    $(document).trigger('ax.toggle.change', {
        target: event.target,
        el: $this.attr('name') || '',
        checked: $this.is(':checked') ? 1 : 0
    });

    if ($this.is(':checked')) { // Enabled
        $('#' + $this.attr('aria-describedby') + ' .tooltip-inner').text('Disable');

        $this.attr('data-original-title', 'Disable').tooltip('update');

        // Trigger events so we can react to different states.
        $(document).trigger('ax.toggle.enabled', {
            el: $this.attr('name') || ''
        });
    } else { // Disabled
        $('#' + $this.attr('aria-describedby') + ' .tooltip-inner').text('Enable');

        $this.attr('data-original-title', 'Enable').tooltip('update');

        // Trigger events so we can react to different states.
        $(document).trigger('ax.toggle.disabled', {
            el: $this.attr('name') || ''
        });
    }
};

// Event handler for "pretty checkbox" package toggle switch checkboxes.
AX.forms.handleAjaxToggle = function (event, data) {
    var $target = $(data.target);

    $.ajax({
        url: $target.attr('data-route'),
        method: 'POST',
        data: {
            enabled: data.checked
        }
    })
        .done(function (response) {
            if (response.status === 200) {
                AX.alert('success', response.message);
                if (data.checked) {
                    $target.parents('tr').removeClass('toggle-row-disabled');
                } else {
                    $target.parents('tr').addClass('toggle-row-disabled');
                }
                $target.trigger('ax.toggle.success', {
                    $toggle: $target,
                    response: response,
                });
            } else {
                AX.log(response);
                AX.alert('error', response.message);
                $target.trigger('ax.toggle.error', {
                    $toggle: $target,
                    response: response,
                });
            }
        })
        .fail(function (jqXHR, textStatus, err) {
            AX.log(err);
            AX.alert('error', err);
            $target.trigger('ax.toggle.error', {
                $toggle: $target,
                response: err
            });
        });
};

// Validate the length of a content editable element.
AX.forms.validateLength = function ($el, clearAlerts) {
    var $this = $el
        , maxlength = parseInt($this.attr('data-maxlength'))
        , name = $this.attr('data-name')
        , value = $this.html().trim();
    if (value.length > maxlength) {
        $this.html(value.substr(0, maxlength));
        AX.forms.setCursor($this.get(0), maxlength);
        return false;
    }
    return true;
};

// Set the cursor position in an input or content editable div.
AX.forms.setCursor = function (el, pos) {
    var range = document.createRange()
        , selection = window.getSelection();
    range.setStart(el.childNodes[0], pos);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
    el.focus();
};

// Add basic preloader spinner for buttons using the class "spin-click".
AX.forms.attachSpinClickHandler = function () {
    $(document)
        .off('click.ax.button.spin-click', '.spin-click')
        .on('click.ax.button.spin-click', '.spin-click', function (event) {
            var $target = $(event.target)
                , width = $target.outerWidth()
                , height = $target.outerHeight()
                , btnHtml = $target.html()
                , preloader = '<i class="fa fa-spin fa-spinner"></i>';

            setTimeout(function () {
                $target.css({
                    width: width + 'px',
                    height: height + 'px',
                })
                    .attr('data-spin-click-html', _.escape(btnHtml))
                    .prop('disabled', true)
                    .addClass('disabled spin-click-disabled')
                    .html(preloader);
            }, 0);
        });
};

// Reset automatically applied button preloader.
AX.forms.resetSpinClick = function (el) {
    setTimeout(function () {
        var $el = $(el)
            , btnHtml = _.unescape($el.attr('data-spin-click-html'));

        $el.prop('disabled', false)
            .removeClass('disabled spin-click-disabled')
            .html(btnHtml);
    }, 200);
};

AX.forms.clearInput = function (options) {
    options = $.extend({}, {
        id: '',
        type: '',
        default: '',
    }, options);

    var $input = $('#' + options.id);

    // Selects.
    if (options.type === 'select') {
        if (options.default) {
            $input.val(options.default);
        } else {
            $input.get(0).selectedIndex = 0;
        }
        $input.trigger('change');
    }

    // Datepicker.
    if (options.type === 'date') {
        $input.val(options.default || '-').trigger('cancel');
    }

    // Text.
    if (options.type === 'text') {
        $input.val(options.default || '');
    }
};
