export default function (config) {
    var utils = {};

    // Abort an ajax request.
    utils.abortRequest = function (ajaxRequestXHR) {
        if (ajaxRequestXHR && ajaxRequestXHR.readyState != 4) {
            AX.log('Aborting previous unloaded partial request.');
            ajaxRequestXHR.abort();
        }
    };

    // Scroll to target card element and highlight for a brief moment.
    utils.scrollToCardHighlight = function (id, callback) {
        var $target = $(id)
            , $card = $target.find('.card');

        if (!$target.length) {
            return;
        }

        $('html, body').animate({
            scrollTop: $target.offset().top - 140
        }, 500);

        setTimeout(function () {
            $card.addClass('shadow-pulse');
            $card.on('animationend', function () {
                $card.removeClass('shadow-pulse');
            });
            typeof callback === 'function' && callback();
        }, 400);
    };

    // Replace broken image with a default one.
    utils.imageError = function (image, replaceWith) {
        var $image = $(image)
            , defaultImg = '/images/default-placeholder.png'
            , placeholder = replaceWith || defaultImg;

        utils.log('Image ' + $image.attr('src') + ' is broken. Replacing with ' + placeholder + '.');

        $image
            .removeAttr('onerror')
            .attr('src', placeholder);
    };

    // Format money string from $10,000 to $10k or $1,000,000 to $1M and so on
    utils.formatNum = function (number) {
        var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

        // what tier? (determines SI symbol)
        var tier = (Math.log10(number) / 3) | 0;

        // if zero, we don't need a suffix
        if (tier == 0) return number;

        // get suffix and determine scale
        var suffix = SI_SYMBOL[tier];
        var scale = Math.pow(10, tier * 3);

        // scale the number
        var scaled = number / scale;

        // format number and add suffix
        return scaled.toFixed(0).toLocaleString() + suffix;
    };

    // Setup copy to clipboard elements and elements and bind click handler.
    utils.initCopyToClipboard = function () {
        var $el = $('[data-ax-clipboard]');

        if ($el.attr('data-ax-clipboard-tooltip') !== "false") {
            $el
                .attr('data-title', 'Copy to Clipboard')
                .attr('data-toggle', 'tooltip')
                .attr('data-boundary', 'viewport')
                .attr('data-container', 'body')
                .tooltip({
                    delay: { show: 600, hide: 100 }
                });
        }

        // Copy to clipboard event handler.
        $(document)
            .off('click', '[data-ax-clipboard]')
            .on('click', '[data-ax-clipboard]', function (event) {
                event.preventDefault();
                event.stopPropagation();
                utils.copyToClipboard(event);
                return false;
            });
    };

    // Copy text to clipboard on click via js.
    utils.copyToClipboard = function (event) {
        var $target = $(event.target).closest('[data-ax-clipboard]')
            , $input = $('<input>')
            , text = $target.text();

        // Check if the data tag has a value, if it does, use that instead
        // of copying the target div's inner text.
        if ($target.attr('data-ax-clipboard')) {
            text = $target.attr('data-ax-clipboard');
        }

        // Add extra css classes to alert element if provided.
        var alertClasses = '';
        if ($target.attr('data-ax-clipboard-classes')) {
            alertClasses = $target.attr('data-ax-clipboard-classes');
        }

        text = $.trim(text);

        $input.attr('style', 'opacity: 1; pointer-events: none;');

        // Check if there is an active modal window open before appending the hidden input.
        if ($('body').hasClass('modal-open') && $('.modal').is(':visible')) {
            $('.modal').append($input);
        } else {
            $('body').append($input);
        }

        // Copy text from input then remove it.
        $input.val(text).select();
        document.execCommand('copy');
        $input.remove();

        AX.alert('success', 'Copied "' + text + '" to the clipboard.', null, null, alertClasses);

        event.stopPropagation();
        event.preventDefault();
        return false;
    };

    // Open first filtered item if there is one, or the first filter if not.
    utils.toggleFirstActiveFilter = function (animated = false, callback) {
        var $filter = $('.is-filtered').first();
        if (animated) {
            // Give bootstrap select a chance to load so we dont see the flicker
            setTimeout(function () {
                var $filter = $('.is-filtered').first();
                if ($filter.length) {
                    $filter.parent().trigger('click');
                } else {
                    $('.filter-toggle').trigger('click');
                }
            }, 100);
        } else {
            if ($filter.length) {
                $filter
                    .parent()
                    .attr('data-aria-expanded', true)
                    .next()
                    .addClass('show');
            } else {
                $('.filter-toggle')
                    .first()
                    .attr('data-aria-expanded', true)
                    .next()
                    .addClass('show');
            }
        }

        // Fire callback.
        if (typeof callback === 'function') {
            $(document).one('shown.bs.collapse', $filter, function (event) {
                callback(event, { filter: $filter });
            });
        }
    };

    // Clear filter sidebar input selects/inputs
    utils.clearFilter = function (event, filterName) {
        event.preventDefault();

        var $target = $(event.target);

        if ($target.prev().is('input')) {
            $("input[name=" + filterName + "]").val("").focus();
            $target.fadeOut();
        } else {
            $("select[name=" + filterName + "]").val("").selectpicker("refresh").data('selectpicker').$button.focus();
        }

        return false;
    };

    // Open link via js
    utils.open = function (event, url, popup, windowTarget) {
        var $target = $(event.target);

        // Ignore window.open call on table row clicks if user is clicking a link or button
        if ($target.hasClass('ignore-events')
            || $target.is('a')
            || $target.parent().is('a')
            || $target.is('button')
            || $target.parent().is('button')
            || $target.is('input')
            || $target.parent().is('input')) {
            return;
        }

        if (typeof popup != 'undefined' && popup) {
            window.open(url, windowTarget || '_blank');
        } else {
            window.location.href = url;
        }
    };

    // Scroll to top of page
    utils.scrollToTop = function (speed) {
        speed = speed || 300;
        $("html, body").animate({ scrollTop: 0 }, speed);
    };

    // Setup back to top button.
    utils.initBackToTop = function ($btn) {
        // Handle initial page load.
        toggleBTTButton();

        // Show/hide button on scroll
        $(window)
            .off('scroll.backToTop', $btn)
            .on('scroll.backToTop', $btn, toggleBTTButton);

        // Scroll on click.
        $btn
            .off('click.backToTop')
            .on('click.backToTop', function (event) {
                event.preventDefault();
                utils.scrollToTop(300);
            });

        function toggleBTTButton() {
            if ($(window).scrollTop() > 300) {
                $btn.fadeIn(500);
            } else {
                $btn.fadeOut(250);
            }
        }
    };

    // Add open on hover option to selectpicker.
    utils.initHoverSelect = function () {
        if (!$('.select-hover-open').length) {
            return;
        }

        $(document)
            .off('mouseenter', '.bootstrap-select.select-hover-open')
            .on('mouseenter', '.bootstrap-select.select-hover-open', function () {
                clearTimeout(window.selectHoverCloseTimeout);
                _togglePicker(this, 'enter');
            })
            .off('mouseleave', '.bootstrap-select.select-hover-open')
            .on('mouseleave', '.bootstrap-select.select-hover-open', function () {
                var _this = this;
                window.selectHoverCloseTimeout = setTimeout(function () {
                    _togglePicker(_this, 'leave');
                }, 500);
            });

        function _togglePicker(ctx, direction) {
            var $this = $(ctx);
            var $selectpicker = $this.parents().find('.select-hover-open .selectpicker');
            var $parent = $this.closest('.select-hover-open');
            if (direction == 'enter' && $parent.children().hasClass('show')) return;
            if (direction == 'leave' && !$parent.children().hasClass('show')) return;
            $selectpicker.selectpicker('toggle');
        }
    };

    // Add/remove shadow to navbar on scroll.
    utils.initNavbarShadow = function () {
        $(window)
            .off('scroll.navbarShadow resize.navbarShadow')
            .on('scroll.navbarShadow resize.navbarShadow', function (event) {
                if ($(this).scrollTop() == 0) {
                    $('.subnav').removeClass('with-shadow').addClass('without-shadow');
                } else {
                    $('.subnav').removeClass('without-shadow').addClass('with-shadow');
                }
            });
    };

    // Initialize misc event handlers.
    utils.attachTableEventHandlers = function () {
        // Enable table drawers.
        $('.has-table-drawer')
            .off('click')
            .on('click', function (event) {
                AX.orders.toggleTableDrawer(event);
            });

        // Keep dropdown menu's open after clicking on their body if tagged with
        // a 'keep-open' class.
        $('.dropdown-menu.keep-open')
            .off('click')
            .on('click', function (event) {
                event.stopPropagation();
            });
    };

    // Ping Slice to set session data via ajax request.
    utils.setSessionValues = function (data) {
        // Abort any previous requests still processing.
        if (typeof window.setSessionXHR !== 'undefined') {
            window.setSessionXHR.abort();
        }

        window.setSessionXHR = $.ajax({
            url: '/session/set',
            method: 'POST',
            data: {
                session_data: data
            }
        })
            .done(function (response) {
                if (response.status && response.status == 200) {
                    $(document).trigger('ax.session.success', response);
                    utils.log('Set session values request successful.', response);
                } else {
                    $(document).trigger('ax.session.fail', response);
                    utils.log('Set session values request failed.', response);
                }
            })
            .fail(function (jqXHR, textStatus, err) {
                $(document).trigger('ax.session.fail', err);
                utils.log('Set session values request failed.', err);
            });

        return window.setSessionXHR;
    };

    // Simple console.log wrapper so we can prepend info, and turn console
    // logging on/off sitewide if need be.
    utils.log = function (...data) {
        var print = config.log.print
            , debug = config.log.debug
            , timestamp = moment().format('h:mm:ss A')
            , caller = (new Error).stack.split("\n")
            , comment = ''
            , styles = [
                'color: #b31e1f; font-weight: 700',
                'color: #2a2a2a; font-weight: 500',
            ];

        // Dump out if print disabled.
        if (!print) {
            return;
        }

        // Check if the first argument is a comment.
        if (typeof arguments[0] === 'string') {
            comment = data.shift();
        }

        // Print timestamp and comment.
        console.log('%c[AX Slice][' + timestamp + ']%c ' + comment, ...styles);

        // Pretty print remaing data.
        if (data.length && debug) {
            $.each(data, function (i, val) {
                try {
                    console.log(val, JSON.stringify(val, null, config.log.indent));
                } catch (err) {
                    console.log(val);
                }
            });
        }

        // Print trace.
        console.log('%c' + (caller[3] || '') + "\n" + (caller[2] || ''), 'color: #777; font-weight: 400');
    };

    return utils;
}
