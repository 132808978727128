export default function (environment) {
    let config = {};

    config.environment = environment;

    config.env = function (env) {
        if (! env) {
            return config.environment;
        }
        if (config.environment === env) {
            return true;
        }
        return false;
    }

    // Enable/disable Sentry.io error reporting.
    config.sentry = {
        dsn     : 'https://e677db964a324d7eaf138e7325a19afd@o380307.ingest.sentry.io/5205973',
        levels  : ['error'],
        enabled : config.env('production'),
    };

    // Enable/disable logrocket session tracking.
    config.logrocket = {
        enabled : config.env('production'),
    };

    // AX.log console.log wrapper settings.
    config.log = {
        indent : 4,                              // JSON indentation.
        print  : ! config.env('production'),     // Print to console.
        debug  : ! config.env('production'),     // Pretty print var dumps.
    };

    config.tickets = {
        log : true,                              // Log js ticket updates & api responses to Sentry.
    };

    return config;
}
