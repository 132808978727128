// AX Slice JS bootstrapping.
require("./bootstrap");

// Main function called on every full page request.
$(document).ready(function () {
    AX.setup();
    AX.init();
});

// Anything that remains gernerally static and does not
// need to be reinitialized.
AX.setup = function () {
    AX.utils.initNavbarShadow();
    AX.utils.initBackToTop($("#backToTop"));
    AX.utils.attachTableEventHandlers();
};

// Anything that may need to be reinitialized for dynamic content,
// such as modals, or page partials loaded via ajax.
AX.init = function () {
    AX.initBootstrapPlugins();
    AX.initBootstrapSelect();
    AX.utils.initCopyToClipboard();
    AX.forms.attachEventHandlers();
};

require('./plugins/jquery.easing');
