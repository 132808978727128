AX.modals = AX.modals || {};

// Register click handler for ajax modals.
$(document)
    .off('click', '[data-toggle="ax-ajax-modal"]')
    .on('click', '[data-toggle="ax-ajax-modal"]', function (event) {
        AX.modals.ajaxModal(event);
        event.preventDefault();
    });

// Register click handler for lightbox modals.
$(document)
    .off('click', '[data-toggle="ax-lightbox"]')
    .on('click', '[data-toggle="ax-lightbox"]', function (event) {
        var $target = $(event.target).closest('[data-toggle="ax-lightbox"]')
            , $image = $target.closest('img')
            , src = $image.attr('src')
            , id = $target.attr('data-id') || 'lightboxModal'
            , inner = $target.attr('data-inner')
            , innerClass = $target.data('inner-class')
            , title = $image.attr('data-lightbox-title') || 'Image';

        // If there is a specified src attribute, use that instead.
        if ($target.closest('[data-lightbox-src]').length) {
            $target = $target.closest('[data-lightbox-src]');
            src = $target.attr('data-lightbox-src');
            title = $target.attr('data-lightbox-title') || 'Image';
        } else {
            AX.log('No attachment image src found.');
        }

        AX.modals.modal({
            action: '',
            title: _.truncate(title, { length: 30 }),
            icon: 'fa fa-image',
            message: '<div class="text-center d-flex align-items-center justify-content-center" style="min-height: 140px;"><div class="position-absolute h-100 w-100 d-flex align-items-center justify-content-center" style="z-index: 1;"><i class="fa fa-cog red fa-spin fa-2x" style="margin-top: -10px;"></i></div><a href="' + src + '" target="_blank" style="z-index: 2;"><img class="ax-lightbox-img" src="' + src + '" title="Download" /></a></div>',
            id: id,
            size: 'fluid modal-lightbox',
            inner: inner,
            innerClass: innerClass,
            cancel: false,
            buttons: [
                '<button class="btn btn-secondary btn-md mr-1" data-dismiss="modal"><i class="far fa-times-circle pr-1"></i> Close</button>'
            ]
        });

        event.preventDefault();
    });

// Dynamic modal with content loaded via ajax request. Settings passed via data tags.
AX.modals.ajaxModal = function (event) {
    var $this = $(event.target).closest('[data-toggle="ax-ajax-modal"]')
        , settings = $this.data()
        , route = settings.route || '/ajax/modal'
        , $modal = $("#modalDefault").clone();

    // 'lg', 'md', 'sm' or nothing at all.
    if (settings.size) {
        $modal.find('.modal-dialog').addClass('modal-' + settings.size);
    }

    $modal.find('.modal-footer').remove();
    $modal.find('.modal-title-inner').text(settings.title || 'AX modal title');
    $modal.find('.modal-title-icon').addClass(settings.icon || 'fa fa-check-circle');
    $modal.attr('id', settings.id || 'generatedAjaxModal_' + Date.now());

    if (typeof settings.view === 'undefined') {
        throw new Error('You must pass a view script path in dot notation to load an ajax modal.');
    }

    $modal.find('.modal-preloader').css({ 'position': 'relative', 'bottom': '-30px' }).fadeIn(175);

    $.ajax({
        url: route,
        method: 'POST',
        data: {
            _token: $('meta[name="csrf-token"]').attr('content'),
            id: $modal.attr('id'),
            view: settings.view,
            data: settings.params,
        }
    })
        .done(function (response) {
            // Load the modal content.
            $modal
                .find('.modal-body-ajax')
                .hide()
                .html(response);

            AX.init();

            $modal.trigger('ax.ajax-modal.loaded', {
                event: event,
                id: $modal.attr('id')
            });

            $modal.find('.modal-preloader').fadeOut(125, function () {
                $(this).remove();

                $modal.find('select').selectpicker('refresh');

                $modal
                    .find('.modal-body-ajax')
                    .slideDown(275, function (event) {
                        setTimeout(function () {
                            $modal.trigger('ax.ajax-modal.visible', {
                                event: event,
                                id: $modal.attr('id')
                            });
                        }, 100);

                        setTimeout(function (event) {
                            // Focus on first visible input, textarea or select after modal is loaded.
                            $modal
                                .find('input.form-control:visible, .dropdown-toggle:visible, textarea.form-control:visible')
                                .first()
                                .focus();
                        }, 25);
                    })
                    .css('opacity', 0)
                    .animate({ opacity: 1 }, { queue: false, duration: 300 });
            });
        })
        .fail(function (jqXHR, textStatus, err) {
            AX.alert('error', 'Failed to load modal.');
            AX.log('Error loading ajax modal: ', err);

            // Show basic error message on failed load instead of hanging preloader.
            $modal.find('.modal-preloader').fadeOut(125, function () {
                $modal
                    .find('.modal-body-ajax')
                    .hide()
                    .html(`
                    <div class="ajax-modal-error d-flex align-items-center justify-content-center flex-column py-5 px-3">
                        <i class="fa fa-2x fa-exclamation-triangle red"></i>
                        <p class="bold m-0 p-0 pt-2">
                            Error Loading Modal
                        </p>
                    </div>
                `)
                    .slideDown(275);
            });
        });

    // Destroy modal on close.
    $modal
        .off('hidden.bs.modal')
        .one('hidden.bs.modal', function (event) {
            AX.log('Removing modal #' + $modal.attr('id'));
            $modal.off().remove();
        });

    if (typeof settings.innerClass !== 'undefined' && settings.innerClass) {
        $modal.addClass(settings.innerClass);
    }

    // Toggle modal backdrop.
    if (typeof settings.inner !== 'undefined' && settings.inner) {
        $modal.addClass('inner-modal').modal({ backdrop: false }).modal('show');
        $modal.trigger('ax.modal.inner');
    } else {
        $modal.modal('show');
    }
};

// Generic js modal.
AX.modals.modal = function (settings) {
    var $modal = $("#modalDefault").clone()
        , cancel = '<button class="btn btn-secondary btn-md mr-1" data-dismiss="modal"><i class="far fa-times-circle pr-1"></i> Cancel</button>'
        , defaultButtons = [cancel];

    // 'xl', 'lg', 'md', 'sm' or nothing at all will default to 'md'
    if (settings.size) {
        $modal.find('.modal-dialog').addClass('modal-' + settings.size);
    }

    // Add ajax form data tag if setting is present.
    if (settings.ajax) {
        $modal.find('form').attr('data-ajax-form', '');
    }

    $modal.attr('id', settings.id || 'generatedModal_' + Date.now());
    $modal.find('.modal-title-inner').text(settings.title || 'AX modal title');
    $modal.find('.modal-title-icon').addClass(settings.icon || 'fa fa-check-circle');
    $modal.find('form').attr('method', settings.method || 'POST').attr('action', settings.action || '');
    $modal.find('.modal-body').html(settings.message || 'AX modal message body');
    $modal.find('.modal-footer').html((settings.cancel ? cancel : '') + ((settings.buttons || settings.cancel) || defaultButtons).join(""));

    // Remove the CSRF token hidden input field if flagged.
    if (typeof settings.csrf !== 'undefined' && settings.csrf == false) {
        $modal.find('form input[name="_token"]').remove();
    }

    // Add hidden inputs if formData setting is present.
    if (typeof settings.formData !== 'undefined' && settings.formData) {
        var inputs = '';
        $.each(settings.formData, function (field, value) {
            inputs += '<input name="' + field + '" value="' + value + '" type="hidden" />';
        });
        $modal.find('form').append(inputs);
    }

    // Add callback tag.
    if (typeof settings.callback !== 'undefined') {
        $modal.find('form').attr('data-callback', settings.callback);
    }

    typeof settings.onInit === 'function' && settings.onInit($modal);

    if (typeof settings.innerClass !== 'undefined' && settings.innerClass) {
        $modal.addClass(settings.innerClass);
    }

    // Toggle modal backdrop.
    if (typeof settings.inner !== 'undefined' && settings.inner) {
        $modal.addClass('inner-modal').modal({ backdrop: false }).modal('show');
        $modal.trigger('ax.modal.inner');
    } else {
        $modal.modal('show');
    }

    // Optional event callbacks.
    $modal
        .off('show.bs.modal')
        .on('show.bs.modal', function (e) {
            typeof settings.onShow === 'function' && settings.onShow($modal);
        });
    $modal
        .off('shown.bs.modal')
        .on('shown.bs.modal', function (e) {
            AX.forms.attachEventHandlers();
            typeof settings.onShown === 'function' && settings.onShown($modal);
        });
    $modal
        .off('hide.bs.modal')
        .on('hide.bs.modal', function (e) {
            typeof settings.onHide === 'function' && settings.onHide($modal);
        });
    $modal
        .off('hidden.bs.modal')
        .on('hidden.bs.modal', function (e) {
            typeof settings.onHidden === 'function' && settings.onHidden($modal);
            $modal.remove();
        });
};

// Delete a resource using data tags.
AX.modals.deleteModal = function (event) {
    var $target = $(event.target).closest('[data-route]')
        , action = $target.data('route')
        , resource = $target.data('resource') || 'Resource'
        , message = $target.data('message') || 'Are you sure you want to delete this ' + _.lowerCase(resource) + '?'
        , icon = $target.data('icon') || 'far fa-trash-alt'
        , isAjax = $target.data('ajax') || false
        , id = $target.data('id') || 'generatedDeleteModal_' + Date.now()
        , inner = $target.data('inner')
        , innerClass = $target.data('inner-class')
        , title = $target.data('title') || 'Delete ' + resource;

    if (typeof action === 'undefined') {
        throw new Error('No action url defined.');
    }

    AX.modals.modal({
        action: action,
        title: title,
        icon: icon,
        message: message,
        cancel: true,
        ajax: isAjax,
        id: id,
        inner: inner,
        innerClass: innerClass,
        buttons: [
            '<input type="hidden" name="_method" value="delete" />',
            '<button type="submit" class="btn btn-primary"><i class="far fa-trash-alt pr-1"></i> Delete</button>'
        ]
    });
};

// Confirm an action using data tags.
AX.modals.confirmModal = function (event) {
    var $target = $(event.target).closest('[data-route]')
        , action = $target.data('route')
        , method = $target.data('method') || 'GET'
        , id = $target.data('id') || 'generatedConfirmModal_' + Date.now()
        , message = $target.data('message') || 'Are you sure you want to?'
        , title = $target.data('title') || 'Please Confirm'
        , confirmText = $target.data('button') || 'Continue'
        , icon = $target.data('icon') || 'far fa-check-circle'
        , isAjax = $target.data('ajax') || false
        , formData = $target.data('form-data') || false
        , inner = $target.data('inner')
        , innerClass = $target.data('inner-class')
        , buttons = $target.data('buttons')
        , csrf = $target.data('csrf');

    if (typeof action === 'undefined') {
        throw new Error('No action url defined.');
    }

    AX.modals.modal({
        action: action,
        method: method,
        icon: icon,
        title: title,
        message: message,
        ajax: isAjax,
        csrf: csrf,
        inner: inner,
        innerClass: innerClass,
        formData: formData,
        id: id,
        cancel: true,
        buttons: buttons || [
            '<button type="submit" class="btn btn-primary"><i class="far fa-check-circle pr-1"></i> ' + confirmText + '</button>',
        ]
    });
};
