require('./datatables');

// Initialize date range picker with default start/end time of 30 days from now.
AX.daterangepicker = function (el, drops = "down", formId = '#subnavDateFilterForm') {
    var $el = $(el);

    if (!$el.length) return;

    var startDate, endDate;
    if ($el.val() === "") {
        // startDate = moment().subtract(29, "days");
        startDate = moment().startOf('day');
        endDate = moment();
    } else {
        var dates = $el
            .val()
            .split(" - ");
        startDate = dates[0];
        endDate = dates[1];
    }

    $el.daterangepicker({
        startDate: startDate,
        endDate: endDate,
        drops: drops,
        opens: "left",
        ranges: {
            Today: [moment(), moment()],
            Yesterday: [
                moment().subtract(1, "days"),
                moment().subtract(1, "days")
            ],
            "Last 7 Days": [
                moment().subtract(6, "days"),
                moment()
            ],
            "Last 30 Days": [
                moment().subtract(29, "days"),
                moment()
            ],
            "This Month": [
                moment().startOf("month"),
                moment().endOf("month")
            ],
            "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month")
            ],
            "Last 3 Months": [
                moment().subtract(3, "month"),
                moment()
            ]
        }
    });

    $el.off("apply.daterangepicker")
        .on("apply.daterangepicker", function (ev, picker) {
            AX.log(picker.startDate.format('YYYY-MM-DD HH:MM:SS'));
            AX.log(picker.endDate.format('YYYY-MM-DD HH:MM:SS'));
            $(formId).submit();
        });
};

// Initialize date range picker with default start/end time of 30 days from now.
AX.daterangepickerpastfuture = function (el, drops = "down") {
    var $el = $(el);

    if (!$el.length) return;

    var startDate, endDate;
    if ($el.val() === "") {
        startDate = moment();
        endDate = moment().add(29, "days");
    } else {
        var dates = $el
            .val()
            .split(" - ");
        startDate = dates[0];
        endDate = dates[1];
    }

    $el.daterangepicker({
        startDate: startDate,
        endDate: endDate,
        drops: drops,
        opens: "left",
        ranges: {
            "Last 3 Months": [
                moment().subtract(3, "month"),
                moment()
            ],
            "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month")
            ],
            "Last 30 Days": [
                moment().subtract(29, "days"),
                moment()
            ],
            "Last 7 Days": [
                moment().subtract(6, "days"),
                moment()
            ],
            Yesterday: [
                moment().subtract(1, "days"),
                moment().subtract(1, "days")
            ],
            Today: [moment(), moment()],
            "Tomorrow": [
                moment().add(1, "days"),
                moment().add(1, "days")
            ],
            "Next 7 Days": [
                moment(),
                moment().add(6, "days")
            ],
            "Next 30 Days": [
                moment(),
                moment().add(29, "days")
            ],
            "This Month": [
                moment().startOf("month"),
                moment().endOf("month")
            ],
            "Next Month": [
                moment().add(1, "month").startOf("month"),
                moment().add(1, "month").endOf("month")
            ],
            "Next 3 Months": [
                moment(),
                moment().add(3, "month")
            ]
        }
    });

    $el.off("apply.daterangepicker")
        .on("apply.daterangepicker", function (ev, picker) {
            AX.log(picker.startDate.format('YYYY-MM-DD HH:MM:SS'));
            AX.log(picker.endDate.format('YYYY-MM-DD HH:MM:SS'));
            $("#subnavDateFilterForm").submit();
        });
};

// Add perfect scrollbar to div.
AX.scrollbar = function (el) {
    var $el = $(el)
        , sb;

    if ($el.length && typeof $el.data('perfect-scrollbar') !== 'undefined') {
        return;
    }

    if (window.matchMedia("(max-width: 767px)").matches) {
        return;
    }

    if ($el.length) {
        sb = new PerfectScrollbar(el, {
            wheelSpeed: 1,
            wheelPropagation: true,
            minScrollbarLength: 100
        });

        $(window)
            .off('resize.ax.scrollbar', el)
            .on('resize.ax.scrollbar', el, function (event) {
                sb.update();
            });

        $el.data('perfect-scrollbar', sb);

        return sb;
    }

    return false;
};

// Initialize bootstrap select plugin on all available selects.
AX.initBootstrapSelect = function () {
    var $select = $('select');
    if ($select.length) {
        $.fn.selectpicker.Constructor.BootstrapVersion = '4';

        $select.each(function (i, el) {
            var $el = $(el);

            if (!$el.parent().hasClass('bootstrap-select')) {
                $el.selectpicker()
                    .off('change.ax.selectpicker-title')
                    .on('change.ax.selectpicker-title', function (event) {
                        $(this).next().removeAttr('title');
                    })
                    .one('loaded.bs.select', function (event) {
                        $(this).next().removeAttr('title');
                    });
            }
        });
    }
};

// Initialize popover/tooltips.
AX.initBootstrapPlugins = function () {
    $('[data-toggle="tooltip"], [data-toggle-second="tooltip"]').tooltip({
        trigger: 'hover',
        delay: { show: 600, hide: 100 },
    });

    $('[data-toggle="popover"], [data-toggle-second="popover"]').popover({
        trigger: 'hover',
        html: true,
        delay: { show: 1000, hide: 100 },
    });
};
