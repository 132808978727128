import utils from './utils';
import config from './config';
import * as Sentry from '@sentry/browser';
// import { init as initSentry, configureScope as configureSentryScope } from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';

window.AX = {};
window._  = require("lodash");
window.$  = window.jQuery = require("jquery");

// Temporary. Remember to remove.
window.Sentry = Sentry;

try {
    AX.config = config(process.env.NODE_ENV);
    AX.utils  = utils(AX.config);
    AX.log    = AX.utils.log; // Shorthand.

    // Temp config overrides.
    // AX.config.log.print         = true;
    // AX.config.log.debug         = true;
    // AX.config.sentry.enabled    = true;
    AX.config.logrocket.enabled = false;

    // Third party packages.
    window.moment = require("moment");
    window.Popper = require("popper.js").default;
    window.PerfectScrollbar = require("perfect-scrollbar/dist/perfect-scrollbar");
    require("bootstrap");
    require("bootstrap-select");
    require('datatables.net-bs4');
    require("daterangepicker");

    // TODO: Refactor into modules.
    require("./plugins/plugins");
    require("./elements/forms");
    require("./elements/alerts");
    require("./elements/modals");

    // Init LogRocket session recording.
    if (AX.config && AX.config.logrocket.enabled) {
        AX.log('Initializing LogRocket Session Tracking.');
        window.LogRocket = require('logrocket');
        LogRocket.init('k4fpwk/ax-slice');
    }

    // Init Sentry.io js console log reporting.
    if (AX.config && AX.config.sentry.enabled) {
        AX.log('Initializing Sentry JS Log Reporting.');

        // initSentry({
        Sentry.init({
            dsn: AX.config.sentry.dsn,
            integrations: [
                new CaptureConsole({
                    levels: AX.config.sentry.levels
                })
            ],
            dist: '1.0.1',
            release: '1.0.1',
            environment: AX.config.env(),
            maxBreadcrumbs: 50
        });

        // configureSentryScope(function(scope) {
        Sentry.configureScope(function(scope) {
            // Add user context.
            scope.setUser({
                id    : $('meta[name="user-id"]').attr('content'),
                name  : $('meta[name="user-name"]').attr('content'),
                email : $('meta[name="user-email"]').attr('content'),
            });
            // Add dev language tag.
            scope.setTag('language', 'javascript');
        });
    }
} catch (e) {
    console.log(e);
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// Add csrf headers for jQuery requests as well.
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    }
});

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel.
 */

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    encrypted: true,
    csrfToken: $('meta[name="csrf-token"]').attr('content'),
    authEndpoint: '/broadcasting/auth',
    namespace: 'ax',
    auth: {
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    }
});

// Enable pusher console logging. Not meant for production.
if (AX.config.env() !== 'production') {
    Pusher.logToConsole = true;
}
